import React from 'react'
import { Container, BackgroundImage, Apropos  } from '../components'

const aPropos = ({data}) => {

  return (
    <Container>
    <BackgroundImage />
    <Apropos />
    </Container>
    )
  }

export default aPropos

